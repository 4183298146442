<template>
    <div id="printContentFooter">
        <legend class="mt-0">Further Information</legend>
        <div class="row top-border">
            <div class="clearfix"></div>
            <div class="col-8 fs-small-8">
                Questions about the Online Reporting Tool may be directed to the Secretariat of the Convention on Biological Diversity.           
            </div>
            <div class="col-4 fs-small-8">
                <strong>Secretariat of the Convention on Biological Diversity</strong>
                <br/> 413 rue Saint-Jacques, suite 800
                <br/> Montreal, Québec, H2Y 1N9
                <br/> Canada
                <br/> Fax: +1 514 288-6588
                <br/> Email: secretariat@cbd.int

            </div>
        </div>
    </div>
</template>